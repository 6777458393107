import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from 'reactstrap';
import { AuthContext } from '../../context/authContext';
import logo from '../../assets/images/logos/logo.png'
import ProfileDD from './ProfileDD';

const Header = () => {
  const { logoutGoogle } = useContext(AuthContext)
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  let user1 = '/images/noUserPicture.jpg';
  
  if( JSON.parse(sessionStorage.getItem("user")).profileObj.picture !== undefined ) {
    user1 = JSON.parse(sessionStorage.getItem("user")).profileObj.picture;
  }

  console.log(user1)
  const logout = () => {
    logoutGoogle()
    window.location.reload()
  }
  return (
    <Navbar
      color="primary"
      dark={!isDarkMode}
      light={isDarkMode}
      className="topbar"
    >
      {/******************************/}
      {/**********Toggle Buttons**********/}
      {/******************************/}
      <div className="d-flex align-items-center">
        <NavbarBrand>
          <img src={logo} alt="Logo corporativo" width="100" height="50" />

        </NavbarBrand>
      </div>

      {/******************************/}
      {/**********Left Nav Bar**********/}
      {/******************************/}

      <div className="d-flex">


        {/******************************/}
        {/**********Profile DD**********/}
        {/******************************/}
        <UncontrolledDropdown>
          <DropdownToggle color="primary">
            <img src={ user1 } alt="profile" className="rounded-circle" width="30" />
          </DropdownToggle>
          <DropdownMenu className="ddWidth">
            <div className="p-2 px-3">
              <ProfileDD picture={ user1 } />
              <Button color="danger" size="sm" onClick={logout} className='mt-2 justify-content-ends'>
                Desconectar
              </Button>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Navbar>
  );
};

export default Header;
