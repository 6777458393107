import { useState } from 'react'
import { Button, ModalHeader, ModalBody, Modal, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'

const ModalSesion = (props) => {
    const [modalState, setModalState] = useState(true)
    const handleModalState = () => {
        if (modalState === true) {
            setModalState(false)

        } else {
            setModalState(true)
        }
    }
    return (
        <Modal isOpen={modalState} centered>
            <ModalHeader>{props.header}</ModalHeader>
            <ModalBody>
                {props.body}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={handleModalState}>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
ModalSesion.propTypes = {
    body: PropTypes.string,
    header: PropTypes.string,
};
export default ModalSesion