import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, List, Tooltip, Col } from 'reactstrap';
import * as Icon from 'react-feather';
import Loader from '../../layouts/loader/Loader';


const DetallePeticion = (props) => {

    const student = JSON.parse(localStorage.getItem('student'));
    const userSession = JSON.parse(sessionStorage.getItem('user'));
    const [collapse, setCollapse] = useState(false);
    const [dataApi, setDataApi] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => { setCollapse(!collapse) }

    const handleRequest = (id, headquarter) => {

        const enrollment = {
            id,
            headquarter
        };

        window.location.href = "/solicitudes-matricula/revision"

        if (localStorage.getItem('enrollment')) {
            localStorage.removeItem('enrollment');
        }
        localStorage.setItem('enrollment', JSON.stringify(enrollment));

    };

    useEffect(() => {

        fetch(`${process.env.REACT_APP_NODE_API}detalleMatricula?headquarter=${props.headquarter}&id=${props.idEnrollment}&userEmail=${userSession.profileObj.email}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-store, no-cache must-revalidate',
                'Pragma': 'no-store',
                'Expires': '0'
            }
        })
            .then((response) => response.json())
            .then((actualData) => setDataApi(actualData))
            .catch(error => {
                sessionStorage.setItem('apiError', error)
                sessionStorage.removeItem('user')
                window.location.reload()
            });

    }, [])

    return (
        <>
            {dataApi.length === 0 ? <Loader /> : <div className="mb-3">
                <div className="border-bottom rounded-top mb-0 px-4 py-2 pe-auto" onClick={toggle} style={{ backgroundColor: '#eef5f9', cursor: 'pointer' }}>
                    <Row>
                        <Col xs="1">
                            <Icon.Mail size={20} className="mt-2" />
                        </Col>
                        <Col xs="8">
                            <h4 className="mt-2">{props.idEnrollment} - {dataApi[0].curso}</h4>
                        </Col>
                        <Col xs="3">
                            {
                                dataApi[0].estado.includes('secretaria')
                                    ? (
                                        <h4 className="mt-2 float-end" style={{ display: 'flex', alignItems: 'center' }}>
                                            <span id="TooltipExample">Pendiente</span>
                                            <Icon.Edit size={20} style={{ marginLeft: '8px' }} onClick={() => { handleRequest(props.idEnrollment, student.empresaId) }} />
                                        </h4>
                                    ) : (
                                        <h4 className="mt-2 float-end">
                                            <span id="TooltipExample">{ dataApi[0].estado }</span>
                                        </h4>
                                    )
                            }
                            <Tooltip placement="top" isOpen={ tooltipOpen } target="TooltipExample" toggle={ () => { setTooltipOpen(!tooltipOpen) }}>
                                {
                                    dataApi[0].estado.includes('secretaria')
                                        ? 'La petición de matrícula está pendiente de revisar por parte de secretaría'
                                        : dataApi[0].estado
                                }
                            </Tooltip>
                        </Col>
                    </Row>

                </div>
                <Collapse isOpen={collapse} className="border px-2 py-2">
                    <List>
                        {dataApi.sort().map((value) => (
                            <li key={value.id}>{value.descripcion}</li>
                        ))}
                    </List>
                </Collapse>
            </div >}
        </>

    )
}

DetallePeticion.propTypes = {
    idEnrollment: PropTypes.any,
    headquarter: PropTypes.any
};

export default DetallePeticion