import { Row, Card, CardBody, Col, Form, FormGroup, Label, Input, Button, CardHeader } from 'reactstrap';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginForm from '../../components/login/LoginForm';
import ModalJSX from "../../layouts/modal/ModalJSX";
import ModalSesion from '../../layouts/modal/ModalSession';

export const Login = ({ sesionExpired }) => {

  const [instructionsModal, setInstructionsModal] = useState(false);
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });

  const instructionsModalBody = (
    <>
      <p>Si tienes problemas o dudas para iniciar sesión, este video te explicará como hacerlo.</p>
      <iframe
        width="100%"
        height="431"
        src="https://www.youtube.com/embed/lMCRps87gQw?si=-ikZ59mQjtdc3TTd"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </>
  );

  const handleInstructionsModal = () => {
    setInstructionsModal( !instructionsModal );
  }

  const handleLogin = () => {

    console.log(loginForm);
    // TODO: Lógica / petición al back

  }

  return (
    <>
      {
        sesionExpired === 'true' ? (
          <ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" />
        ) : (
          ''
        )
      }
      { instructionsModal ? <ModalJSX titulo="Instrucciones para iniciar sesión" contenido={ instructionsModalBody } modal={ instructionsModal } setModal={ setInstructionsModal } ancho='lg' /> : '' }
      <Row>
        <Col sm="6" style={{ paddingLeft: '29.6px'}}>
          <Card className="animate__animated animate__zoomIn">
            <CardHeader className="text-center">¿Ya dispones de correo CEDETEC?</CardHeader>
            <CardBody>
              <p>Seleccione el centro. CEDESCA / CNTEC</p>
              <small className="pb-4 d-block">
                Recuerde usar su cuenta de <b>cedetecgroup.com</b>
              </small>
              <LoginForm />
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" style={{ paddingRight: '29.6px'}}>
          <Card className="animate__animated animate__zoomIn">
            <CardHeader className="text-center">¿No tienes correo de CEDETEC?</CardHeader>
            <CardBody style={{ paddingBottom: 0 }}>
              <Form>
                <FormGroup>
                  <Label for="email">Correo electrónico</Label>
                  <Input type="email" name="email" id="email" placeholder="ejemplo@gmail.com" onChange={ e => setLoginForm( prev => ({ ...prev, email: e.target.value })) } />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Contraseña</Label>
                  <Input type="password" name="password" id="password" onChange={ e => setLoginForm( prev => ({ ...prev, password: e.target.value })) } />
                </FormGroup>
                <Button color="primary" onClick={ handleLogin } style={{ width: '100%', marginTop: '56px' }}>Iniciar sesión</Button>
                <p style={{ textAlign: 'right', margin: '12px 0 8px' }}>No estás registrado? <Link to="/auth/registro">Regístrate</Link></p>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12" style={{ padding: '0 29.6px'}}>
          <Button
              className="animate__animated animate__zoomIn"
              color="primary"
              onClick={ handleInstructionsModal }
              style={{ width: '100%' }}
          >
              Instrucciones para iniciar sesión
          </Button>
        </Col>
      </Row>
    </>
  );
};

Login.propTypes = {
  sesionExpired: PropTypes.string,
};