import { Navigate } from 'react-router-dom';
import { Login, Registro } from '../views/login';

/*****Routes******/

const LoginRoutes = [
  {
    path: '/',
    exact: true,
    element: <Login />,
  },
  {
    path: '/auth/login',
    exact: true,
    element: <Login />,
  },
  {
    path: '/auth/registro',
    exact: true,
    element: <Registro />,
  },
  {
    path: '/*',
    element: <Navigate to='/auth/login' />
  }
];

export default LoginRoutes;
