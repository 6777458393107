import {useState, useEffect} from 'react'
import { TabContent } from 'reactstrap'
import PropTypes from 'prop-types'
import DatosAlumnos from "./DatosAlumnos"
import Loader from '../../layouts/loader/Loader'
import DatosTutores from "./DatosTutores"

const PestañasDatos = (props) => {
    const { empresaId } = JSON.parse(localStorage.getItem('student'));
const [countryApi, setCountryApi]=useState('')
useEffect(() => {
    fetch(`${process.env.REACT_APP_NODE_API}cargarPaises?empresaId=${empresaId}&userEmail=${JSON.parse(sessionStorage.getItem('user')).profileObj.email}`, {
        method: 'GET',
        redirect: 'follow',
        mode: 'cors',
        cache: "no-store",
        referrerPolicy: "no-referrer",
        credentials: "same-origin",
        headers: {
            'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
            'content-type': 'application/json',
            'Cache-Control': 'no-store, no-cache must-revalidate',
            'Pragma': 'no-store',
            'Expires': '0'
        }
    })
        .then((response) => response.json())
        .then((actualData) => setCountryApi(actualData))
},[])
    return (
        <>
            {props.dataApi === ('') ? <Loader /> :
                props.dataApi.datos === null ? <p className="mt-2 text-danger"><strong>Este alumno no dispone de datos.</strong></p> : <TabContent className="p-4" activeTab={props.activeTab}>
                    <DatosAlumnos datosAlumno={props.dataApi} paises={countryApi}/>
                    <DatosTutores datosTutor={props.dataApi} />
                </TabContent>
            }

        </>


    )
}

PestañasDatos.propTypes = {
    activeTab: PropTypes.string,
    dataApi: PropTypes.any
};
export default PestañasDatos