import PropTypes from 'prop-types'
import { ModalBody, Modal } from 'reactstrap'
import Loader from '../loader/Loader'

const ModalLoader = (props) => {
    return (
        <Modal isOpen centered>
            <ModalBody>
                <h2 className="text-center mt-3">{props.header}</h2>
                <Loader />
            </ModalBody>
        </Modal>
    )
}
ModalLoader.propTypes = {
    header: PropTypes.any
};
export default ModalLoader