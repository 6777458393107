import NuevaMatricula from '@asubarrocacedetec/auto-mat';
import { Navigate } from 'react-router-dom';
import DetallesUsuario from '../views/details/DetallesUsuario';

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    exact: true,
    element: <DetallesUsuario />,
  },
  {
    path: '/matriculas/nueva',
    exact: true,
    element: <NuevaMatricula variant='portal' deployment={process.env.REACT_APP_DEPLOYMENT} />,
  },
  {
    path: '/*',
    element: <Navigate to='/' />
  }
];

export default ThemeRoutes;
