import { useState } from "react"
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import ModalJSX from "../../layouts/modal/ModalJSX";

export const Registro = () => {

    const [modalError, setModalError] = useState(false);
    const [registroForm, setRegistroForm] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        password2: ''
    });

    const handleRegistro = () => {

        if( registroForm.password !== registroForm.password2 ) {
            setModalError(true);
        }

    }

    return (
        <>
            { modalError ? <ModalJSX titulo="Error" contenido="Las contraseñas no coinciden" modal={ modalError } setModal={ setModalError } /> : '' }
            <Row>
                <Col sm="8" style={{ margin: '0 auto' }}>
                    <Card className="animate__animated animate__zoomIn">
                        <CardHeader className="text-center">Regístrate en nuestro portal</CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label for="name">Nombre</Label>
                                    <Input type="text" name="name" id="name" onChange={ e => setRegistroForm( prev => ({ ...prev, name: e.target.value })) } />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="surname">Apellidos</Label>
                                    <Input type="text" name="surname" id="surname" onChange={ e => setRegistroForm( prev => ({ ...prev, surname: e.target.value })) } />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Correo electrónico</Label>
                                    <Input type="email" name="email" id="email" placeholder="ejemplo@gmail.com" onChange={ e => setRegistroForm( prev => ({ ...prev, email: e.target.value })) } />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Contraseña</Label>
                                    <Input type="password" name="password" id="password" onChange={ e => setRegistroForm( prev => ({ ...prev, password: e.target.value })) } />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password2">Repite la contraseña</Label>
                                    <Input type="password" name="password2" id="password2" onChange={ e => setRegistroForm( prev => ({ ...prev, password2: e.target.value })) } />
                                </FormGroup>
                                <Button color="primary" onClick={ handleRegistro }>Registrarse</Button>
                            </Form>
                            <p style={{ textAlign: 'right', margin: '12px 0 0' }}>Ya tienes cuenta? <Link to="/auth/login">Iniciar sesión</Link></p>
                        </CardBody>
                    </Card>
                </Col>
        </Row>
        </>
    )
}