import { React, useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const AuthContext = createContext('hola')

const initialState = null

export const ContextProvider = (props) => {

    const [user, setUser] = useState(initialState)
    const [userError, setUserError] = useState(false)

    const loginGoogle = (response, empresaId) => {

        setUser(response)

        fetch(`${process.env.REACT_APP_URL_API}buscarAlumnos.php?email=${response.profileObj.email}&empresaId=${empresaId}`, {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: "no-cache",
            referrerPolicy: "no-referrer",
            credentials: "same-origin",
            headers: {
                'Authorization': `Bearer ${response.tokenId}`,
                'content-type': 'application/json',
            }
        }
        )
            .then((res) =>
                res.json()
            )

            .then((actualData) => {
                if (actualData.length > 0) {
                    const student = { id: actualData[0].id, empresaId: actualData[0].empresaId, email: response.profileObj.email }
                    const studentStorage = { id: actualData[0].id, empresaId: actualData[0].empresaId, nombre: actualData[0].nombre, apellidos: actualData[0].apellidos }
                    sessionStorage.setItem('student', JSON.stringify(student))
                    localStorage.setItem('student', JSON.stringify(studentStorage))
                    sessionStorage.setItem('user', JSON.stringify(response))
                    window.location.reload()
                } else {
                    setUserError(true)
                }
            })
    }

    const logoutGoogle = () => {
        setUser(null)
        sessionStorage.removeItem('error')
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('student')
    }

    return (
        <AuthContext.Provider
            value={{
                user, loginGoogle, logoutGoogle, userError
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}
ContextProvider.propTypes = {
    children: PropTypes.node,
};