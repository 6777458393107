import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logos/logo.png';
// import bg from '../assets/images/bg/bg.jpg';
import '../assets/styles/login.css';

if(process.env.REACT_APP_DEPLOYMENT === 'local'){
    import('../assets/scss/stylelocal.scss');
    }else if(process.env.REACT_APP_DEPLOYMENT === 'test'){
    import('../assets/scss/styletest.scss');
    }else{
    import('../assets/scss/style.scss');
    }

export const LoginLayout = ({ children }) => {

    const location = useLocation();

    let estilo = {
        height: '100vh',
        padding: 0,
        backgroundColor: '#f1b956'
        // backgroundImage: `url(${ bg })`,
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover'
    }

    if (process.env.REACT_APP_DEPLOYMENT === 'test'){
        estilo = {
            height: '100vh',
            padding: 0,
            backgroundColor: '#3c184f'
            // backgroundImage: `url(${ bg })`,
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover'
        }
    }else if (process.env.REACT_APP_DEPLOYMENT === 'local'){
        estilo = {
            height: '100vh',
            padding: 0,
            backgroundColor: '#3d6611'
            // backgroundImage: `url(${ bg })`,
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover'
        }
    }

    return (
        <Container style={{ margin: 0, maxWidth: 'none', height: '100vh' }}>
            <Row>
                <Col sm="4" style={estilo}>
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignContent: 'flex-end'
                    }}>
                        <Link
                            to="/auth/login"
                            className={ location.pathname === '/auth/login' || location.pathname === '/' ? 'login-btn login-btn-active' : 'login-btn' }
                        >
                            Iniciar sesión
                        </Link>
                        <Link
                            to="/auth/registro"
                            className={ location.pathname === '/auth/registro' ? 'login-btn login-btn-active' : 'login-btn' }
                        >
                            Registrarse
                        </Link>
                    </div>
                </Col>
                <Col sm="8" style={{ height: '100vh', overflow: 'auto' }}>
                        <Row style={{ flexDirection: 'column' }}>
                            <Col style={{ maxWidth: '530px', margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={logo} alt="Imagen corporativa" width="300" />
                                <p>¡Bienvenido al portal de CEDETEC Group!</p>
                            </Col>
                            <Col>
                                { children }
                            </Col>
                        </Row>
                </Col>
            </Row>
        </Container>
    )

    // return (
    //     <div className="loginBox">
    //         {
    //             sesionExpired === 'true' ? (
    //                 <ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" />
    //             ) : (
    //                 ''
    //             )
    //         }
    //         <LeftBg className="position-absolute left bottom-0" />
    //         <RightBg className="position-absolute end-0 top" />
    //         <Container fluid id="loginBox" className="h-100 animate__animated animate__zoomIn">
    //             <Row className="justify-content-center align-items-center h-100">
    //                 <Col lg="12" className="loginContainer">
    //                     <Card className="text-center">
    //                         <img src={logo} alt="Imagen corporativa" width="300" className="mx-auto" />
    //                         <CardBody className="p-4 m-1">
    //                             { children }
    //                         </CardBody>
    //                     </Card>
    //                 </Col>
    //             </Row>
    //         </Container>
    //     </div>
    // )
}

LoginLayout.propTypes = {
    children: PropTypes.element
};