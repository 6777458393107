import Detalles from '../../components/usuarios/Detalles';


const DetallesUsuario = () => {

    return (
        <>
            <Detalles />
        </>
    )
}

export default DetallesUsuario