import { Button, ModalHeader, ModalBody, Modal, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'

const ModalJSX = ({ titulo, contenido, modal, setModal, ancho = '' }) => {
    
    const handleModal = () => {
        setModal( !modal );
    }

    return (
        <Modal isOpen={ modal } centered size={ ancho } >
            <ModalHeader>{ titulo }</ModalHeader>
            <ModalBody>
                { contenido }
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={ handleModal }>
                    Cerrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
ModalJSX.propTypes = {
    titulo: PropTypes.string,
    contenido: PropTypes.element,
    modal: PropTypes.bool,
    setModal: PropTypes.func,
    ancho: PropTypes.string
};
export default ModalJSX