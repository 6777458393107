import { TabPane } from 'reactstrap'
import PropTypes from 'prop-types'
import Tutor from './Tutor'


const DatosTutores = (props) => {
    const arrayTutores = props.datosTutor.datos.tutores
    return (
        <TabPane tabId="2">

            {
                arrayTutores.map((tutor) => <Tutor key={tutor.dni} nombre={tutor.nombre} apellidos={tutor.apellidos}
                    telefono={tutor.telefono} dni={tutor.dni} email={tutor.email} parentesco={tutor.parentesco} />)
            }

        </TabPane>
    )
}
DatosTutores.propTypes = {
    datosTutor: PropTypes.any
};
export default DatosTutores