import { useState, useEffect } from 'react';
import { TabPane, Row, Col, Input, Form, Label, Button} from 'reactstrap'
import * as Icon from 'react-feather';
import PropTypes from 'prop-types'
import ModalClickable from '../../layouts/modal/ModalClickable';
import ModalOptions from '../../layouts/modal/ModalOptions';

const DatosAlumnos = (props) => {
  
    const { empresaId } = JSON.parse(localStorage.getItem('student'));
    const [isPoblationWrong, setIsPoblationWrong] = useState(false)
    const [raw, setRaw] = useState('');
    const [url, setUrl] = useState('');
  const [isCPWrong, setIsCPWrong] = useState(false)
  const [isCountryWrong, setIsCountryWrong] = useState(false)
  const [isFormSubmittable, setIsFormSubmittable] = useState(true);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalShown2, setIsModalShown2] = useState(false);
  const [poblacion, setPoblacion] = useState('');
  const [cp, setCp] = useState('');
  const [direccion, setDireccion] = useState({
    data: props.datosAlumno.datos.personales.direccion,
    error: '',
  });
  const [isCountryFound, setIsCountryFound] = useState('')
    const [isPoblationFound, setIsPoblationFound] = useState('')
    const [isCPFound, setIsCPFound]= useState('')
    useEffect(() => {
        let pobl = props.datosAlumno.datos.personales.poblacion;
        pobl = pobl.replace(/[áä]/g, 'a');
        pobl = pobl.replace(/[éë]/g, 'e');
        pobl = pobl.replace(/[íï]/g, 'i');
        pobl = pobl.replace(/[óö]/g, 'o');
        pobl = pobl.replace(/[úü]/g, 'u');
    
        // Reemplazar acentos abiertos
        pobl = pobl.replace(/[à]/g, 'a');
        pobl = pobl.replace(/[è]/g, 'e');
        pobl = pobl.replace(/[ì]/g, 'i');
        pobl = pobl.replace(/[ò]/g, 'o');
        pobl = pobl.replace(/[ù]/g, 'u');
    
        // Reemplazar acentos cerrados
        pobl = pobl.replace(/[á]/g, 'a');
        pobl = pobl.replace(/[é]/g, 'e');
        pobl = pobl.replace(/[í]/g, 'i');
        pobl = pobl.replace(/[ó]/g, 'o');
        pobl = pobl.replace(/[ú]/g, 'u');
      
        const arrayPaises = props.paises.filter(objeto => objeto.nom ===  props.datosAlumno.datos.personales.lugarnac);
    
            if(arrayPaises.length > 0){
              setIsCountryFound(arrayPaises[0].nom)
            }else{
              setIsCountryWrong(true)
            }


    
        fetch(
          `${process.env.REACT_APP_NODE_API}cargarPoblaciones?empresaId=${empresaId}&userEmail=${
            JSON.parse(sessionStorage.getItem('user')).profileObj.email
          }&municipio=${pobl}`,
          {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
              'content-type': 'application/json',
              'Cache-Control': 'no-store, no-cache must-revalidate',
              'Pragma': 'no-store',
              'Expires': '0'
            },
          },
        )
          .then((response) => response.json())
          .then((actualDataa) => {
            const arrayFiltrado = actualDataa.filter(objeto => objeto.nom_municipi ===  props.datosAlumno.datos.personales.poblacion);
    
            if(arrayFiltrado.length > 0){
              setIsPoblationFound(arrayFiltrado[0].nom_municipi)
            }else{
              setIsPoblationWrong(true)
            }
            
    
            setPoblacion(actualDataa)
          });
    
        fetch(
          `${process.env.REACT_APP_NODE_API}cargarCp?empresaId=${empresaId}&userEmail=${
            JSON.parse(sessionStorage.getItem('user')).profileObj.email
          }&municipio=${pobl}`,
          {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
              'content-type': 'application/json',
              'Cache-Control': 'no-store, no-cache must-revalidate',
              'Pragma': 'no-store',
              'Expires': '0'
            },
          },
        )
          .then((response) => response.json())
          .then((actualDataa) => {
            const arrayFiltrado = actualDataa.filter(objeto => objeto.nom_municipi === props.datosAlumno.datos.personales.poblacion)
            
            if(arrayFiltrado.length > 0){
              setIsCPFound(props.datosAlumno.datos.personales.cp)
            }else{
              setIsCPWrong(true)
            }
            setCp(arrayFiltrado);
            
          });
    
          
      }, []);
      const handleLugarnac = (e) => {
        setIsCountryFound(e.target.value);
        setIsCountryWrong(false)
      };
      const handleCp = (e) => {
        setIsCPFound(e.target.value);
        setIsCPWrong(false)
      };
      const handleDireccion = (e) => {
        setDireccion({
          data: e.target.value,
          error: '',
        });
      };
      const handlePoblacion = (e) => {
        setIsPoblationFound(e.target.value);
        setIsPoblationWrong(false)
        setIsCPWrong(false)
        const pobl = e.target.value;
    
        fetch(
          `${process.env.REACT_APP_NODE_API}cargarCp?empresaId=${empresaId}&userEmail=${
            JSON.parse(sessionStorage.getItem('user')).profileObj.email
          }&municipioId=${pobl}`,
          {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
              'content-type': 'application/json',
              'Cache-Control': 'no-store, no-cache must-revalidate',
              'Pragma': 'no-store',
              'Expires': '0'
            },
          },
        )
          .then((response) => response.json())
          .then((actualDataa) => {
            setIsCPFound('')
            setCp(actualDataa.filter(objeto => objeto.nom_municipi === pobl));
          });
      };

      const handleOnSubmit = (e) => {
        e.preventDefault();
        const userSession = JSON.parse(sessionStorage.getItem('user'));
        let isUpdated = 0;
        const empresa = JSON.parse(localStorage.getItem('student')).empresaId;
        const lastUpdate = JSON.parse(localStorage.getItem('lastUpdate'));
        fetch(
          `${process.env.REACT_APP_NODE_API}comprobarActualizacion?empresaId=${empresa}&alumno=${props.datosAlumno.id}&userEmail=${userSession.profileObj.email}&date=${lastUpdate}`,
          {
            method: 'GET',
            redirect: 'follow',
            mode: 'cors',
            cache: 'no-store',
            referrerPolicy: 'no-referrer',
            credentials: 'same-origin',
            headers: {
              Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
              'content-type': 'application/json',
              'Cache-Control': 'no-store, no-cache must-revalidate',
              'Pragma': 'no-store',
              'Expires': '0'
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            isUpdated = data;
            if (isUpdated === 0) {
              setIsModalShown(true);
              setIsFormSubmittable(false);
            } else {
              setUrl(`${process.env.REACT_APP_NODE_API}actualizarUsuario?empresaId=${JSON.parse(localStorage.getItem('student')).empresaId}&origin=portal`)
              setRaw(JSON.stringify({
                id: props.datosAlumno.id,
                nacimiento: isCountryFound,      
                poblacion: isPoblationFound,
                cp: isCPFound,
                calle: direccion.data,
                userEmail: userSession.profileObj.email,
                headquarter: JSON.parse(localStorage.getItem('student')).empresaId,
              }))
              setIsModalShown2(true)
              
            }
          });
      };
    return (
<>
      {isModalShown ? (
        <ModalClickable header="Un usuario ha modificado este alumno recientemente." />
      ) : (
        ''
      )}
      {isModalShown2 ? <ModalOptions header="¿Desea realizar estos cambios?" url={url} raw={raw} load={setIsModalShown2}/> : ''}
        <TabPane tabId="1">
            <Form onSubmit={handleOnSubmit}>
                <Row>
                    <Col sm="2" className="mt-1 mt-md-0">
                        <Label htmlFor="idalu"><small>IDALU</small></Label>
                        <Input type="text" name="idalu" id="idalu" value={props.datosAlumno.datos.personales.idalu === null ? '' : props.datosAlumno.datos.personales.idalu} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-0">
                        <Label htmlFor="name"><small>Nombre</small></Label>
                        <Input type="text" name="name" id="name" value={props.datosAlumno.datos.personales.nombre === null ? '' : props.datosAlumno.datos.personales.nombre} disabled />
                    </Col>
                    <Col sm="5" className="mt-1 mt-md-0">
                        <Label htmlFor="lastname" className=""><small>Apellidos</small></Label>
                        <Input type="text" name="apellidos" id="lastname" value={props.datosAlumno.datos.personales.apellidos === null ? '' : props.datosAlumno.datos.personales.apellidos} disabled />
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="dni"><small>DNI</small></Label>
                        <Input type="text" name="dni" id="dni" value={props.datosAlumno.datos.personales.dni === null ? '' : props.datosAlumno.datos.personales.dni} disabled />
                    </Col>
                </Row>
                <Row>
                <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="birthday"><small>Fecha de nacimiento</small></Label>
                        <Input type="text" name="birthday" id="birthday" value={props.datosAlumno.datos.personales.fechanac === null ? '' : props.datosAlumno.datos.personales.fechanac} disabled />
                    </Col>
                    
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="born"><small>País de nacimiento</small></Label>
                        <Input type="select" name="born" id="born" onChange={handleLugarnac} value={isCountryFound === '' ? '' : isCountryFound} className={isCountryWrong ? 'border border-danger error' : ''}>
                {props.paises !== ''
                  ? props.paises.map((option) => (
                      <option key={option.idcodi_pais} value={option.nomctual}>
                        {option.nom}
                      </option>
                    ))
                  : ''}
              </Input>
              {isCountryWrong  && (
                <span className="text-danger tiny">Corrija el país</span>
              )}
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                        <Label htmlFor="city"><small>Municipio de residencia</small></Label>
                        <Input type="select" name="city" id="city" onChange={handlePoblacion} value={isPoblationFound === '' ? '' : isPoblationFound} className={isPoblationWrong ? 'border border-danger error' : ''}>
                {poblacion !== ''
                  ? poblacion.map((option) => (
                      <option key={option.nom_municipi} value={option.nom_municipi}>
                        {option.nom_municipi}
                      </option>
                    ))
                  : ''}
              </Input>
              {isPoblationWrong  && (
                <span className="text-danger tiny">Corrija la población</span>
              )}
                    </Col>
                    <Col sm="2" className="mt-1 mt-md-1">
                    <Label htmlFor="cp">
                <small>Código postal</small>
              </Label>

              <Input type="select" name="cp" id="cp" onChange={handleCp} value={isCPFound === '' ? '' : isCPFound} className={isCPWrong ? 'border border-danger error' : ''}>
                {cp !== ''
                  ? cp.map((option) => (
                      <option key={option.cp} value={option.cp}>
                        {option.cp}
                      </option>
                    ))
                  : ''}
              </Input>
              {isCPWrong  && (
                <span className="text-danger tiny">Corrija el código postal</span>
              )}
                    </Col>
                    <Col sm="4" className="mt-1 mt-md-1">
                        <Label htmlFor="street"><small>Dirección</small></Label>
                        <Input type="text" name="street" id="street" onChange={handleDireccion} defaultValue={direccion === null ? '' : direccion.data} className={direccion.error !== '' ? 'border border-danger error' : ''}/>
                        {direccion.error === 'length' && (
                                <span className="text-danger tiny">Debe tener mínimo 2 caracteres</span>
                            )}
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="email1"><small>Email 1</small></Label>
                        <Input type="text" name="email1" id="email1" value={props.datosAlumno.datos.personales.email1 === null ? '' : props.datosAlumno.datos.personales.email1} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="email2"><small>Email 2</small></Label>
                        <Input type="text" name="email2" id="email2" value={props.datosAlumno.datos.personales.email2 === null ? '' : props.datosAlumno.datos.personales.email2} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="phone"><small>Teléfono 1</small></Label>
                        <Input type="text" name="phone" id="phone" value={props.datosAlumno.datos.personales.telefono1 === null ? '' : props.datosAlumno.datos.personales.telefono1} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="phone2"><small>Teléfono 2</small></Label>
                        <Input type="text" name="phone2" id="phone2" value={props.datosAlumno.datos.personales.telefono2 === null ? '' : props.datosAlumno.datos.personales.telefono2} disabled />
                    </Col>

                </Row>
                <Row>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="nss"><small>Número seguridad social</small></Label>
                        <Input type="text" name="nss" id="nss" value={props.datosAlumno.datos.personales.nsegsoc === null ? '' : props.datosAlumno.datos.personales.nsegsoc} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="healthcare"><small>Tarjeta sanitaria</small></Label>
                        <Input type="text" name="healthcare" id="healthcare" value={props.datosAlumno.datos.personales.ntsi === null ? '' : props.datosAlumno.datos.personales.ntsi} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="studies"><small>Estudios anteriores</small></Label>
                        <Input type="text" name="studies" id="studies" value={props.datosAlumno.datos.personales.estudantnom === null ? '' : props.datosAlumno.datos.personales.estudantnom} disabled />
                    </Col>
                    <Col sm="3" className="mt-1 mt-md-1">
                        <Label htmlFor="datestudies"><small>Fecha de los últimos estudios</small></Label>
                        <Input type="text" name="datestudies" id="datestudies" value={props.datosAlumno.datos.personales.estudantffin === null ? '' : props.datosAlumno.datos.personales.estudantffin} disabled />
                    </Col>
                </Row>
                    <Row>
            <Col sm="12" className="mt-1 mt-md-0">
              <Button
                color="success"
                className={isFormSubmittable && !isCPWrong && !isPoblationWrong && !isCountryWrong ? 'mt-3 w-auto' : 'mt-3 w-auto disabled'}
              >
                <a>
                  <Icon.Save />
                </a>
              </Button>
              
              
            </Col>
          </Row>
            </Form>

        </TabPane>
        </>)
}
DatosAlumnos.propTypes = {
    datosAlumno: PropTypes.any,
    paises: PropTypes.any,
};
export default DatosAlumnos