import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types'
import Header from './header/Header';
import Footer from '../components/usuarios/Footer'


const PortalLayout = ({ children }) => {
    const LayoutHorizontal = useSelector((state) => state.customizer.isLayoutHorizontal);

    return (
        <>
            <main>
                <div className="pageWrapper d-md-block d-lg-flex">
                    <div className='contentArea'>
                        <Header />
                        <Container fluid className="p-4 boxContainer">
                            <div className={LayoutHorizontal ? 'HsidebarFixed' : ''}>
                                { children }
                            </div>
                        </Container>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

PortalLayout.propTypes = {
    children: PropTypes.node,
};

export default PortalLayout