import { React, useContext, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { decodeToken } from "react-jwt";
import { Col, Form, Row } from "reactstrap";
import { AuthContext } from "../../context/authContext";
import HeadquarterInput from "./HeadquarterInput";
import HeadquartersData from "./HeadquartersData";
import ModalSesion from "../../layouts/modal/ModalSession";

if(process.env.REACT_APP_DEPLOYMENT === 'local'){
    import('../../assets/scss/stylelocal.scss');
    }else if(process.env.REACT_APP_DEPLOYMENT === 'test'){
    import('../../assets/scss/styletest.scss');
    }else{
    import('../../assets/scss/style.scss');
    }

const LoginForm = () => {

    const headquarters = HeadquartersData;
    const { loginGoogle, userError } = useContext(AuthContext);
    const [empresaId, setEmpresaId] = useState('CEDESCA');

    const responseGoogle = ({ credential }) => {

        // Obtenemos loss datos del token
        const decodedToken = decodeToken(credential);
        loginGoogle({ tokenId: credential, profileObj: decodedToken }, empresaId);

    }

    const onChangeRadioValue = (e) => {
        setEmpresaId(e.target.value);
    }

    return (
        <Form>
            { userError === true ? <ModalSesion body="Usuario no encontrado." header="Algo ha salido mal" /> : '' }
            <Row>
                {
                    headquarters.map((headquarter) => (
                        <Col key={headquarter.name}>
                            <center>
                                <HeadquarterInput key={headquarter.name} headquarter={headquarter.name} imgUrl={headquarter.url} onChange={onChangeRadioValue} />
                            </center>
                        </Col>
                    ))
                }
            </Row>
            <Row className="mt-4">
                <Col>
                    <GoogleLogin
                        onSuccess={ responseGoogle }
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default LoginForm