/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
//import React, { Suspense } from 'react';
import React, { useContext, useState } from 'react';
import { useRoutes } from 'react-router-dom'
import ModalSesion from "./layouts/modal/ModalSession"
import Themeroutes from './routes/Router';
import { AuthContext } from './context/authContext'
import PortalLayout from './layouts/PortalLayout';
import LoginRoutes from './routes/LoginRouter';
import { LoginLayout } from './layouts/LoginLayout';

const App = () => {

  const { logoutGoogle } = useContext(AuthContext);
  const loginRouting = useRoutes(LoginRoutes);
  const routing = useRoutes(Themeroutes);
  const userSession = JSON.parse(sessionStorage.getItem('user'));
  const [isVerified] = useState(1);

  let render = null;

  const onLoad = (verified) => {

    if (sessionStorage.getItem('user') === null) {

      render = <LoginLayout>{ loginRouting }</LoginLayout>;
      // render = <LoginOld />;

    } else if (userSession !== null) {
      if (verified === 0) {

        logoutGoogle();
        render = <><ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" /><LoginLayout>{ loginRouting }</LoginLayout></>; // <LoginLayout>{ loginRouting }</LoginLayout>
        // render = <><ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" /><Login /></>;

        sessionStorage.removeItem('user');
        sessionStorage.removeItem('student');

      } else if (JSON.parse(sessionStorage.getItem('error')) !== null) {

        logoutGoogle();
        render = <><ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" /><LoginLayout>{ loginRouting }</LoginLayout></>;
        // render = <><ModalSesion body="Por favor, vuelva a iniciar sesión." header="Sesión expirada" /><Login /></>;

      } else {

        render = <PortalLayout>{ routing }</PortalLayout>;

      }
    }

    return render
  }


  return (
    <>
      { onLoad(isVerified) }
    </>
  )
};

export default App;