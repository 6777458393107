import { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import * as Icon from 'react-feather';
import ComponentCard from "../../layouts/containers/ComponentCard"
import DetallePeticion from './DetallePeticion';
import Loader from '../../layouts/loader/Loader'

const PeticionMatricula = () => {

    const student = JSON.parse(localStorage.getItem('student'))
    const userSession = JSON.parse(sessionStorage.getItem('user'))

    const [dataApi, setDataApi] = useState('')

    const handleOnClick = () => {
        window.location.href = "/matriculas/nueva"
    }

    useEffect(() => {
        const localStudent = JSON.parse(localStorage.getItem('student'))
        fetch(`${process.env.REACT_APP_NODE_API}peticionActiva?userEmail=${userSession.profileObj.email}&headquarter=${localStudent.empresaId}&id=${localStudent.id}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${JSON.parse(sessionStorage.getItem('user')).tokenId}`,
                'content-type': 'application/json',
                'Cache-Control': 'no-store, no-cache must-revalidate',
                'Pragma': 'no-store',
                'Expires': '0'
            }
        })
        .then((response) => response.json())
        .then((actualData) => {

            if( actualData.error ) {
                
                sessionStorage.setItem('apiError', actualData.error);
                sessionStorage.removeItem('user');
                window.location.reload();

            } else {
                setDataApi(actualData)
            }
        })
    }, []);

    return (
        <ComponentCard title="Peticiones de matrícula">
            {dataApi === '' ? <Loader /> : dataApi.length === 0 ? <>
                <a>No dispones de peticiones activas.</a>
                <Button className="float-end col-1 w-auto" color="success" >
                    <a>
                        <Icon.Plus onClick={handleOnClick} />
                    </a>
                </Button>
            </> : <DetallePeticion idEnrollment={dataApi[0].NumMatricula} headquarter={student.empresaId} />}


        </ComponentCard >
    )
}

export default PeticionMatricula