import React from 'react'
import PropTypes from 'prop-types'
import ComponentCard from "../../layouts/containers/ComponentCard"
import Loader from '../../layouts/loader/Loader'
import Matricula from './Matricula'


const Matriculaciones = (props) => {
    return (
        <ComponentCard title="Matriculaciones">
            {props.matriculas === undefined ? <Loader /> : props.matriculas.map((matricula) => <Matricula key={matricula.id} matricula={matricula} />)}
        </ComponentCard >
    )
}

Matriculaciones.propTypes = {
    matriculas: PropTypes.any
};

export default Matriculaciones